/**
 * @generated SignedSource<<3af8b0e7234da7ecdd5e9c7e8934e467>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Role = "ROLE_ADMIN_ACCESS" | "ROLE_ATTENTIVE_ACCOUNT" | "ROLE_CLIENT_ACCOUNT" | "ROLE_CLIENT_ADMIN_ACCOUNT" | "ROLE_CLIENT_LIMITED_ACCOUNT" | "ROLE_CLIENT_MANAGED_ACCOUNT" | "ROLE_CLIENT_MANAGED_ADMIN_ACCOUNT" | "ROLE_CLIENT_MEMBER_ACCOUNT" | "ROLE_CLIENT_STRATEGY" | "ROLE_CONCIERGE" | "ROLE_CONCIERGE_OPERATIONS" | "ROLE_CS_TAM" | "ROLE_E2E_TEST_USER" | "ROLE_ENGINEERING" | "ROLE_ENGINEERING_IDENTITY" | "ROLE_ENGINEERING_SALES" | "ROLE_ENGINEERING_SOLUTIONS" | "ROLE_IT_SECURITY" | "ROLE_MESSAGING_OPS" | "ROLE_PRODUCT_MANAGEMENT" | "ROLE_READONLY_CONCIERGE" | "ROLE_SUBSCRIBER_AUDITOR" | "ROLE_SUPER_USER" | "ROLE_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DatadogUserAcoreUtilsFragment_query$data = {
  readonly company: {
    readonly displayName?: string;
    readonly id: string;
    readonly internalId?: number;
    readonly name?: string;
  } | null;
  readonly viewer: {
    readonly account: {
      readonly email: string;
      readonly id: string;
    } | null;
    readonly roles: {
      readonly roles: ReadonlyArray<Role>;
    } | null;
  } | null;
  readonly " $fragmentType": "DatadogUserAcoreUtilsFragment_query";
};
export type DatadogUserAcoreUtilsFragment_query$key = {
  readonly " $data"?: DatadogUserAcoreUtilsFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"DatadogUserAcoreUtilsFragment_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DatadogUserAcoreUtilsFragment_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Viewer",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "companyId",
              "variableName": "companyId"
            }
          ],
          "concreteType": "GetRolesResponse",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roles",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "company",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "companyId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "internalId",
              "storageKey": null
            }
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "eeddbd5447d66b384ac16dc557c9c934";

export default node;
