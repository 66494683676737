import React, { useState, useEffect, useRef, useCallback } from 'react';
import { commitLocalUpdate } from 'relay-runtime';
import { useRelayEnvironment } from 'react-relay';
import {
  Card,
  Heading,
  IconButton,
  keyframes,
  PicnicCss,
  Box,
  styled,
  Text,
} from '@attentive/picnic';

import { createSiteRecords } from '@attentive/data';
import * as Portal from '@radix-ui/react-portal';

import toastSuccessSrc from '../../assets/toast-success.png';
import toastErrorSrc from '../../assets/toast-error.png';
import toastSyncingSrc from '../../assets/toast-syncing.png';
import { EventBus } from '../EventBus';
import { useToast } from './use-toast';
import { useQueuedToasts } from './use-queued-toasts';
import { ToastType } from './Toast';

// eslint-disable-next-line @typescript-eslint/naming-convention
const PU_slideIn = keyframes({
  '0%': {
    transform: 'translateX(50px)',
    opacity: 0,
  },
  '100%': {
    transform: 'translateX(0)',
    opacity: 1,
  },
});

// eslint-disable-next-line @typescript-eslint/naming-convention
const PU_slideOut = keyframes({
  '0%': {
    transform: 'translateX(0)',
    opacity: 1,
  },
  '100%': {
    transform: 'translateX(50px)',
    opacity: 0,
  },
});

const PU_WIDTH = 380;
const TOAST_TIMEOUT = 5 * 1000; // 5 seconds

const cardStyle: PicnicCss = {
  zIndex: '$layer4',
  marginTop: '$space8',
  right: '$space3',
  position: 'fixed',
  width: PU_WIDTH,
  padding: '$space4',
  boxShadow: '$shadow3',
  animation: `${PU_slideIn} 250ms ease-out`,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
};

const cardHide: PicnicCss = {
  opacity: 0,
  animation: `${PU_slideOut} 250ms ease-out`,
};

const iconButtonStyle: PicnicCss = {
  right: '$space2',
  top: '$space2',
  position: 'absolute',
};

const ImageStyled = styled('img', {
  width: '$size14',
  height: '$size14',
  marginRight: '$space4',
  alignSelf: 'center',
});

const ToastIcon: React.FC<{ type?: string | null }> = ({ type }) => {
  if (type === ToastType.Success) {
    return <ImageStyled src={toastSuccessSrc} alt={type} />;
  } else if (type === ToastType.Error) {
    return <ImageStyled src={toastErrorSrc} alt={type} />;
  } else if (type === ToastType.Syncing) {
    return <ImageStyled src={toastSyncingSrc} alt={type} />;
  }

  return null;
};

interface ToastContainerProps {
  anchorElementId?: string;
}

const ToastContainer: React.FC<ToastContainerProps> = ({ anchorElementId }) => {
  const relayEnvironment = useRelayEnvironment();
  const queuedToasts = useQueuedToasts();
  const [createToast] = useToast();
  const [currentToast, setCurrentToast] = useState<(typeof queuedToasts)[0] | null>(null);
  const [isToastExiting, setIsToastExiting] = useState(false);
  const topOffset = useRef(0);
  const dismissTimerId = useRef(0);

  const handleLegacyCreateToast = useCallback(
    (e: CustomEvent) => {
      createToast(e.detail);
    },
    [createToast]
  );

  const handleAnimationEnd = () => {
    // We only care about the exit animation (slide out), not the entry (slide in)
    if (isToastExiting) {
      setCurrentToast(null);
      setIsToastExiting(false);
    }
  };

  const handleDismissToast = () => {
    setIsToastExiting(true);
    window.clearTimeout(dismissTimerId.current);
  };

  const computeTopOffset = React.useCallback(() => {
    const appContentEl = anchorElementId ? document.getElementById(anchorElementId) : null;

    if (appContentEl) {
      return appContentEl.getBoundingClientRect().top;
    }

    return 0;
  }, [anchorElementId]);

  const dequeueNextToast = useCallback(() => {
    commitLocalUpdate(relayEnvironment, (relayStore) => {
      const [nextToast, ...nextQueue] = queuedToasts;
      setCurrentToast(nextToast);
      createSiteRecords(relayStore, 'Toast', 'toasts', nextQueue);
      topOffset.current = computeTopOffset();
      dismissTimerId.current = window.setTimeout(handleDismissToast, TOAST_TIMEOUT);
    });
  }, [relayEnvironment, queuedToasts, computeTopOffset]);

  React.useEffect(() => {
    if (!currentToast && queuedToasts.length) {
      dequeueNextToast();
    }
  }, [currentToast, queuedToasts, dequeueNextToast]);

  useEffect(() => {
    return () => {
      window.clearTimeout(dismissTimerId.current);
    };
  }, []);

  useEffect(() => {
    EventBus.subscribe('toast:create', handleLegacyCreateToast);
    return () => {
      EventBus.unsubscribe('toast:create', handleLegacyCreateToast);
    };
  }, [handleLegacyCreateToast]);

  if (!currentToast) {
    return null;
  }

  return (
    <Portal.Root>
      <Card
        onAnimationEnd={handleAnimationEnd}
        data-testid="toast-container-card"
        css={{
          ...cardStyle,
          ...(isToastExiting ? cardHide : {}),
          top: topOffset.current,
        }}
      >
        <IconButton
          onClick={handleDismissToast}
          size="small"
          iconName="X"
          description="Close Button"
          css={iconButtonStyle}
        />
        <ToastIcon type={currentToast.type} />
        <Box>
          <Heading
            variant="sm"
            css={{ color: currentToast.type === ToastType.Error ? '$error' : undefined }}
          >
            {currentToast.title}
          </Heading>
          <Text>{currentToast.text}</Text>
        </Box>
      </Card>
    </Portal.Root>
  );
};

export { ToastContainer };
